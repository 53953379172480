import { TPromoBreederCode } from "../../auth/hooks/useGetPromoBreederCode/useGetPromoBreederCode.types";
import { IMe, IUserData } from "../../auth/types/user";
import { DropdownType } from "../../common/Dropdown";
import { IFullScreenMedia } from "../../common/FullScreenMedia/FullScreenMedia.types";
import { ActionMap } from "../../types/common";
import { IAssociation } from "../types/association";
import { IBreed } from "../types/breed";
import { ICurrencyDropdown } from "../types/currency";

export interface IAppState {
    me?: IMe;
    breeds?: IBreed[];
    associations?: IAssociation[];
    associationList?: DropdownType[];
    currencies?: ICurrencyDropdown[];
    fullScreenMedia: IFullScreenMedia;
    promoBreederCode?: string;
};

export enum EAppActionType {
    updateMe = "UPDATE_ME",
    setBreeds = "SET_BREEDS",
    setAssociations = "SET_ASSOCIATIONS",
    setAssociationList = "SET_ASSOCIATION_LIST",
    updateUser = "UPDATE_USER",
    setCurrencies = "SET_CURRENCIES",
    showFullScreenMedia = "SHOW_FSMEDIA",
    hideFullScreenMedia = "HIDE_FSMEDIA",
    setPromoBreederCode = "SET_PROMO_BREEDER_CODE"
};

interface IAppActionPayload {
    [EAppActionType.updateMe]: {
        me: IMe | undefined;
    };  
    [EAppActionType.setBreeds]: {
        breeds: IBreed[];
    };
    [EAppActionType.setAssociations]: {
        associations: IAssociation[];
    };
    [EAppActionType.setAssociationList]: {
        associationList: DropdownType[];
    };
    [EAppActionType.updateUser]: {
        user: IUserData;
    };
    [EAppActionType.setCurrencies]: {
        currencies: ICurrencyDropdown[];
    };
    [EAppActionType.showFullScreenMedia]: {
        fullScreenMedia: IFullScreenMedia;
    };
    [EAppActionType.hideFullScreenMedia]: {};
    [EAppActionType.setPromoBreederCode]:Pick<TPromoBreederCode, "code">
};

export type TAppAction = ActionMap<IAppActionPayload>[keyof ActionMap<IAppActionPayload>];
