import { ReactNode, ComponentProps } from "react";
import { FunctionComponent } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useGetMe from "./app/hooks/useGetMe/useGetMe";
import { appRoutes } from "./app/routes";
import { authRoutes } from "./auth/routes";
import { listingsRoutes } from "./listings/routes";
import { AuthRoute } from "./common/AuthRoute/AuthRoute";
import Layout from "./common/Layout";
import { ranchRoutes } from "./ranch/routes";
import { marketPlaceRoutes } from "./marketPlace/routes";
import { IRouteDescriptor } from "./types/common";
import { bovinesRoutes } from "./bovines/routes";
import { kpiRoutes } from "./kpi/routes";
import Footer from "./common/Footer/Footer";

export const Routes: FunctionComponent<ComponentProps<any>> = () => {
    const mapRoutes = (
        routes: IRouteDescriptor
    ): ReactNode => {
        return Object.keys(routes).map(
            (key: string, index: number) => {
                const descriptor: IRouteDescriptor = {route: routes[key]};
                return (
                    <Route
                        key={index}
                        exact={descriptor.route.exact}
                        path={descriptor.route.pattern}
                        render={routerProps => (
                            <AuthRoute
                                key={index}
                                descriptor={descriptor}
                                {...routerProps}/>
                        )}
                    />
                );
        });
    }
    const {
        result,
    } = useGetMe({});
    return (
        <BrowserRouter>
            {result !== undefined && (
                <Layout>
                        <Switch>
                            {mapRoutes(appRoutes)}
                            {mapRoutes(authRoutes)}
                            {mapRoutes(marketPlaceRoutes)}
                            {mapRoutes(ranchRoutes)}
                            {mapRoutes(listingsRoutes)}
                            {mapRoutes(bovinesRoutes)}
                            {mapRoutes(kpiRoutes)}
                        </Switch>
                        <Footer/>
                </Layout>
            )}
        </BrowserRouter>
    )
}