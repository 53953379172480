// import auth from "../public/locales/en/auth.json";
// import home from "../public/locales/en/home.json";
// import common from "../public/locales/en/common.json";
// import ranch from "../public/locales/en/ranch.json";
// import market from "../public/locales/en/market.json";
// import listing from "../public/locales/en/listing.json";
// import bovine from "../public/locales/en/bovine.json";
// import dialog from "../public/locales/en/dialog.json";
// import navigation from "../public/locales/en/navigation.json";

type TResource = {
  [key: string]: string;
}

export const supportedLanguages = ["en", "es"];

export const defaultNS: TTranslationNamespace = "translation";
// export const resources =  {
  // auth,
  // bovine,
  // common,
  // home,
  // listing,
  // market,
  // ranch,
  // dialog,
  // navigation,
// } as const;

export type TTranslationNamespace = "auth"
  | "ranch" | "home" | "listing" | "bovine"
  | "market" | "dialog" | "navigation" | "common"
  | "translation" | "rules" | "help" | "terms_and_conditions";

// export type TResources = Map<TTranslationNamespace, TResource>

// export type TResources = {
//   auth: typeof resources["auth"];
//   ranch: typeof resources["ranch"];
//   home: typeof resources["home"];
//   listing: typeof resources["listing"];
//   bovine: typeof resources["bovine"];
//   market: typeof resources["market"];
//   dialog: typeof resources["dialog"];
//   navigation: typeof resources["navigation"];
//   common: typeof resources["common"];
//   translation: {[key: string]: string};
// }

export type TResources = {
  auth: TResource;
  ranch: TResource;
  home: TResource;
  listing: TResource;
  bovine: TResource;
  market: TResource;
  dialog: TResource;
  navigation: TResource;
  common: TResource;
  translation: TResource;
  rules: TResource;
  help: TResource;
  terms_and_conditions: TResource;
}

// export type TTranslationNamespace = keyof TResources;
