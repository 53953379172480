import { createContext, useReducer, Dispatch, useMemo, FunctionComponent } from 'react';
import { TChildrenProps } from '../../types/common';
import { IMonthlyUsersKpiReport, IWeeklyUsersKpiReport } from '../types/KPIs';
import { KpiActionTypes, KpiAction, KpiState } from './KpiContext.types';

export const WEEKLY_USERS_KPI_REPORT_INITIAL_VALUES : IWeeklyUsersKpiReport = {
  date: '',
  new_breeders: 0,
  new_users: 0,
  previous_weeks: {
    end: "",
    new_breeders: 0,
    new_users: 0,
    start: "",
    wau: 0,
    wau_growth: 0
  },
  start: '',
  wau: 0
}

export const MONTHLY_USERS_KPI_REPORT_INITIAL_VALUES : IMonthlyUsersKpiReport = {
  date: '',
  mau: 0,
  new_breeders: 0,
  new_users: 0,
  previous_months: {
    mau: 0,
    mau_growth: 0,
    month: 0,
    new_breeders: 0,
    new_users: 0,
    year: 0
  }
}

export const INITIAL_STATE: KpiState = {
  monthlyUsersKpiReport : MONTHLY_USERS_KPI_REPORT_INITIAL_VALUES,
  weeklyUsersKpiReport : WEEKLY_USERS_KPI_REPORT_INITIAL_VALUES
};

const KpiContext = createContext<{
  state: KpiState;
  dispatch: Dispatch<any>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const reducer = (state: KpiState, action: KpiAction): KpiState => {
  switch (action.type) {

    case KpiActionTypes.SET_MONTHLY_KPI:
      state = {
        ...state,
        monthlyUsersKpiReport: action.result,
      };
      return state;

    case KpiActionTypes.SET_WEEKLY_KPI:
      state = {
        ...state,
        weeklyUsersKpiReport: action.result,
      };
      return state;

    default:
      return state;
  }
};

const KpiContextProvider: FunctionComponent<TChildrenProps> = ({
  children
}) => {
  const stateWithCache = {
    ...INITIAL_STATE,
  };

  const [state, dispatch] = useReducer(reducer, stateWithCache);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <KpiContext.Provider value={{ ...contextValue }}>{children}</KpiContext.Provider>;
};

export { KpiContextProvider, KpiContext };
