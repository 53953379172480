import { makeStyles } from '@material-ui/core';

export const useFooterStyles = makeStyles((theme) => ({
  container:{
    backgroundColor: '#1C3308',
    padding: theme.spacing(3.5),
    marginTop: theme.spacing(3),
    '& div:first-child':{
      marginTop: theme.spacing(2),
    }
  },
  buttonLink: {
    justifyContent: 'center',
    color: theme.palette.common.white,
    borderRadius: '3px',
    fontWeight: 300,
    '&:hover':{
      textDecoration: 'underline'
    },
  },
  terms:{
    marginTop: theme.spacing(3),
  }
}));
