import { lazy } from "react";
import { IRouteDescriptor } from "../types/common";
import { KpiContextProvider } from "./context/KpiContext";
const KPI = lazy(() => import("./pages/KPI/KPI"));

enum EKPIRoutes {
	KPI = 'kpi',
}

export const kpiRoutes: IRouteDescriptor = {
	[EKPIRoutes.KPI]: {
		pattern: ['/kpi'],
		url: '/kpi',
		component: KPI,
		exact: true,
		provider: KpiContextProvider,
		protect: true
	},
};
