import { FunctionComponent, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppBar, Drawer, IconButton, Toolbar, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from './Menu';
// Assets
import { useHistory } from 'react-router';
import { Logo } from '../assets/components/logo';

const useStyles = makeStyles<Theme>((theme) => ({
  logo: {
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));

export type NavBarProps = {};

const NavBar: FunctionComponent<NavBarProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { push } = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  return (
    <AppBar color="inherit" position="static" elevation={2}>
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpenMenu(true)}>
              <MenuIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
            <Drawer variant="temporary" anchor={'left'} open={openMenu} onClose={() => setOpenMenu(false)}>
              <Menu isMobile={isMobile} closeDrawer={() => setOpenMenu(false)} />
            </Drawer>
          </>
        ) : (
          <>
            <div className={classes.logo} onClick={() => push('/')}>
              <Logo alt="logo"/>
            </div>
            <Menu />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
