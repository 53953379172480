import React from 'react';
import { Zoom, Fab, FabProps, useTheme, makeStyles, Theme, createStyles, Portal } from '@material-ui/core';
interface Coords {
    top?: any,
    right?: any,
    bottom?: any,
    left?: any,
}
interface CustomFabProps extends FabProps {
  ariaLabel: string,
  clickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void,
  position?: string,
  coords?: Coords,
  disablePortal?: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: (props: any) => {
    const {position, coords} = props;
    const {top, right, bottom, left} = coords;

    return {
        position,
        top,
        right,
        bottom,
        left,
      }
  },
  })
);

const CustomFab: React.FC<CustomFabProps> = ({ ariaLabel, color, size, children, clickHandler, disablePortal = false, ...props}) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  return (
    <Portal container={document.body} disablePortal={disablePortal}>
      <Zoom in={true} timeout={transitionDuration} unmountOnExit>
        <Fab
          aria-label={ariaLabel}
          color={color}
          className={classes.fab}
          onClick={clickHandler}
          size={size}
        >
          {children}
        </Fab>
      </Zoom>
    </Portal>
  );
};

export default CustomFab;
