import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import {colors} from './styles';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {GillSansR, HelveticaR} from './fonts';

export const theme = createMuiTheme({
  palette: {
    common: {
      white: colors.common.white,
      black: colors.common.black,
    },
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: colors.primary.main,
      dark: colors.primary.dark,
      light: colors.primary.light,
    },
    secondary: {
      main: colors.secondary.main,
      dark: colors.secondary.dark,
      light: colors.secondary.main,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  typography: {
    fontFamily: ["Helvetica", "sans-serif"].join(","),
    h1: {
      fontSize: "1.383rem",
      fontWeight: 700,
      color: colors.primary.dark,
      marginBottom: ".5em",
    },
    h2: {
      fontSize: "1.296rem",
      fontWeight: 700,
      color: colors.primary.dark
    },
    h3: {
      fontSize: "1.215rem",
      fontWeight: 700,
      color: colors.primary.dark
    },
    h4: {
      fontSize: "1.138rem",
      fontWeight: 700,
      color: colors.primary.dark
    },
    h5: {
      fontSize: "1.067rem",
      color: colors.primary.dark
    },
    h6: {
      fontSize: "1rem",
      color: colors.primary.dark
    },
    subtitle1: {
      color: colors.text.secondary,
      fontSize: "1rem",
    },
    subtitle2: {
      color: colors.text.secondary,
      fontSize: "0.937rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    button: {
      fontSize: "1rem",
      fontWeight: 700,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "0.625rem",
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.text.other,
        fontSize: "19px",
      fontWeight: 500,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
