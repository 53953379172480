import { DropdownType } from "../../common/Dropdown";
import { ActionMap } from "../../types/common";
import { IRanchProfile, IRanchProfileResumed } from "../types/ranch";

export interface IRanchState {
    ranches?: IRanchProfileResumed[];
    ranchDetail?: IRanchProfile;
};

export enum ERanchActionType {
    setRanches = "SET_RANCHES",
    setRanchDetail = "SET_RANCH_DETAIL"
};

interface IRanchActionPayload {
    [ERanchActionType.setRanches]: {
        ranches: IRanchProfileResumed[],
    };
    [ERanchActionType.setRanchDetail]: {
        ranchDetail: IRanchProfile,
    };
};

export type TRanchAction = ActionMap<IRanchActionPayload>[keyof ActionMap<IRanchActionPayload>];

export type TLanguage = "es" | "en";

export type ILanguageDropdown<M extends DropdownType> = {
    [K in TLanguage]: M[];
}

export const RANCH_PHONE_NUMBER_NATURES: ILanguageDropdown<DropdownType> = {
    en: [
        { label: "Main", value: "MAIN" },
        { label: "Support", value: "SUPPORT" },
        { label: "Sales", value: "SALES" },
        { label: "Management", value: "ADMIN" },
        { label: "Miscellaneous", value: "MISCELLANEOUS" },
        { label: "Other", value: "OTHER" }
    ], 
    es: [
        { label: "Principal", value: "MAIN" },
        { label: "Soporte", value: "SUPPORT" },
        { label: "Ventas", value: "SALES" },
        { label: "Gerencia", value: "ADMIN" },
        { label: "Asuntos generales", value: "MISCELLANEOUS" },
        { label: "Otro", value: "OTHER" }
    ]
};

export const EMAIL_NATURES: ILanguageDropdown<DropdownType> = {
    en: [
        { label: "Main", value: "MAIN" },
        { label: "Support", value: "SUPPORT" },
        { label: "Sales", value: "SALES" },
        { label: "Management", value: "ADMIN" },
        { label: "Miscellaneous", value: "MISCELLANEOUS" },
        { label: "Other", value: "OTHER" }
    ], 
    es: [
        { label: "Principal", value: "MAIN" },
        { label: "Soporte", value: "SUPPORT" },
        { label: "Ventas", value: "SALES" },
        { label: "Gerencia", value: "ADMIN" },
        { label: "Asuntos generales", value: "MISCELLANEOUS" },
        { label: "Otro", value: "OTHER" }
    ]
};