import { EAppActionType, IAppState, TAppAction } from "./AppContext.types";


export const INIT_APP_STATE: IAppState = {
    me: undefined,
    breeds: [],
    associations: [],
    associationList: [],
    fullScreenMedia: {
        show: false,
    },
    promoBreederCode: undefined,
};

export function appStateInit(incomingState?: IAppState): IAppState {
    return Object.assign(
        INIT_APP_STATE, 
        incomingState,
    );
};

export function appContextReducer(
    state: IAppState,
    action: TAppAction,
): IAppState {
    switch (action.type) {
        case EAppActionType.updateMe:
            return {
                ...state,
                me: action.status.me, 
            };
        case EAppActionType.setBreeds:
            return {
                ...state,
                breeds: action.status.breeds,
            };
        case EAppActionType.setAssociations:
            return {
                ...state,
                associations: action.status.associations,
            };
        case EAppActionType.setAssociationList:
            return {
                ...state,
                associationList: action.status.associationList,
            };
        case EAppActionType.updateUser:
            return {
                ...state,
                me: {
                    user: action.status.user,
                    jwt: state.me?.jwt || "",
                }
            };
        case EAppActionType.setCurrencies:
            return {
                ...state,
                currencies: action.status.currencies,
            };
        case EAppActionType.showFullScreenMedia:
            return {
                ...state,
                fullScreenMedia: {
                    show: true,
                    image: action.status.fullScreenMedia.image,
                    video: action.status.fullScreenMedia.video,
                    mediaIndex: action.status.fullScreenMedia.mediaIndex,
                    mediaSet: action.status.fullScreenMedia.mediaSet,
                }
            };
        case EAppActionType.hideFullScreenMedia:
            return {
                ...state,
                fullScreenMedia: {
                    show: false,
                    image: undefined,
                    video: undefined,
                }
            };
        case EAppActionType.setPromoBreederCode:
            return {
                ...state,
                promoBreederCode: action.status.code
            }
    };
};
