import { ComponentProps, createContext, Dispatch, FunctionComponent, useReducer } from "react";
import { appContextReducer, appStateInit, INIT_APP_STATE } from "./AppContext.reducer";
import { IAppState, TAppAction } from "./AppContext.types";

// handles localstorage and static data

export const AppStateContext = createContext<IAppState>(INIT_APP_STATE);
export const AppDispatchContext = createContext<Dispatch<TAppAction>>(() => null);

export const AppContextProvider: FunctionComponent<ComponentProps<any>> = ({
    ...props
}) => {
    const [state, dispatch] = useReducer(
        appContextReducer,
        undefined,
        appStateInit,
    );
    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {props.children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};
