import { lazy } from "react";
import { IRouteDescriptor } from "../types/common";
import { RanchContextProvider } from "./context/RanchContext";
const CreateRanchBreed = lazy(() => import("./pages/CreateRanchBreed.tsx/CreateRanchBreed"));
const RanchProfile = lazy(() => import('./pages/Profile/Profile'));
const CreateRanch = lazy(() => import('./pages/CreateRanch/CreateRanch'));


enum ERanchRoutes {
    CreateRanch = 'createRanch',
    RanchProfile = 'ranchProfile',
    CreateListing = 'createListing',
    CreateRanchBreed = 'createRanchBreed',
}

export const ranchRoutes: IRouteDescriptor = {
    [ERanchRoutes.CreateRanch]: {
        pattern: ['/ranch/create'],
        url: '/ranch/create',
        component: CreateRanch,
        exact: true,
        provider: RanchContextProvider,
        protect: true,
    },
    [ERanchRoutes.CreateRanchBreed]: {
        pattern: ['/ranch/breeds'],
        url: '/ranch/breeds',
        exact: true,
        component: CreateRanchBreed,
        provider: RanchContextProvider,
        protect: true,
    },
    [ERanchRoutes.RanchProfile]: {
        pattern: ['/ranch', '/ranch/:ranchId'],
        url: '/ranch',
        exact: true,
        component: RanchProfile,
        provider: RanchContextProvider,
        protect: true,
    }
};
