import { IGTMTags, TDataLayerArgs, TIframeArgs, TScriptArgs, TSnippetsArgs } from "./Snippets.types";

export class Snippets {
    iframe: string;
    script: string;
    dataLayerVar: string;
    constructor(args: TSnippetsArgs) {
        const tags = this.buildTags(args);
        this.iframe = tags.iframe;
        this.script = tags.script;
        this.dataLayerVar = tags.dataLayerVar;
    }
    buildIframe(args: TIframeArgs): string {
        return `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${args.id}${args.gtmAuth}${args.gtmPreview}&gtm_cookies_win=x"
            height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
    }
    buildScript(args: TScriptArgs): string {
        return `
            (function(w,d,s,l,i){w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(args.events).slice(1, -1)}});
                var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${args.gtmAuth}${args.gtmPreview}&gtm_cookies_win=x';
                f.parentNode.insertBefore(j,f);
            })(window,document,'script','${args.dataLayerName}','${args.id}');`;
    }
    static buildDataLayer(args: TDataLayerArgs): string {
        return `
            window.${args.dataLayerName} = window.${args.dataLayerName} || [];
            window.${args.dataLayerName}.push(${JSON.stringify(args.dataLayer)})`;
    }
    buildTags(args: TSnippetsArgs): IGTMTags {
        const gtmAuth = `&gtm_auth=${args.auth}`;
        const gtmPreview = `&gtm_preview=${args.preview}`;
        if (!args.id) throw new Error("GTM id is required 😐");
        const iframe = this.buildIframe({
            id: args.id,
            gtmAuth,
            gtmPreview
        });
        const script = this.buildScript({
            id: args.id,
            events: args.events,
            dataLayerName: args.dataLayerName,
            gtmAuth,
            gtmPreview
        });
        const dataLayerVar = Snippets.buildDataLayer({
            dataLayer: args.dataLayer, 
            dataLayerName: args.dataLayerName
        });
        return {iframe, script, dataLayerVar};
    }
}
