import React, { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme';
import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import Axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { AppContextProvider } from './app/context/AppContext';
import { Routes } from './Routes';

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
const cache = new LRU({ max: 100 });

configure({ cache, axios });

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Suspense fallback={null}> */}
          {/** In a future we can apply a spinner while we are waiting **/}
          <SnackbarProvider maxSnack={1}>
            <AppContextProvider>
              <Routes />
            </AppContextProvider>
          </SnackbarProvider>
        {/* </Suspense> */}
      </ThemeProvider>
    </>
  );
};

export default App;
