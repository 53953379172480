import { ERanchActionType, IRanchState, TRanchAction } from "./RanchContext.types";


export const INIT_RANCH_STATE: IRanchState = {
    ranchDetail: undefined,
    ranches: undefined,
};

export function ranchStateInit(incomingState?: IRanchState): IRanchState {
    return Object.assign(
        INIT_RANCH_STATE,
        incomingState
    );
};

export function ranchContextReducer(
    state: IRanchState,
    action: TRanchAction,
): IRanchState {
    switch (action.type) {
        case ERanchActionType.setRanches:
            return {
                ...state,
                ranches: action.status.ranches,
            };
        case ERanchActionType.setRanchDetail:
            return {
                ...state,
                ranchDetail: action.status.ranchDetail,
            };
    };
};
