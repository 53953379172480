import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultNS, supportedLanguages } from "./i18n.types";


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        LocalStorageBackend,
        HttpApi
      ],
      backendOptions: [{
        prefix: 'i18next_res_',
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        defaultVersion: '',
        versions: {},
        store: window.localStorage
      }, {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      }]
    },
    load: "languageOnly",
    ns: [
      "home",
      "auth", 
      "ranch",
      "listing",
      "bovine",
      "market",
      "dialog",
      "navigation",
      "rules",
      "common"],
    supportedLngs: supportedLanguages,
    fallbackLng: "en",
    debug: false,
    interpolation: { escapeValue: false },
    detection: {
      order: ["navigator", "path"]
    },
    preload: supportedLanguages,
    react: {
      useSuspense: true,
    },
    defaultNS: defaultNS,
  });

export default i18n;