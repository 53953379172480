import { lazy } from "react";
import { IRouteDescriptor } from "../types/common";
import { MarketPlaceProvider } from "./context/MarketplaceContext";
const PublicRanch = lazy(() => import("./pages/PublicRanch/PublicRanch"));
const PublicListing = lazy(() => import("./pages/PublicListing/PublicListing"));
const MarketPlace = lazy(() => import("./pages/MarketPlace/MarketPlace"));
const PublicBovine = lazy(() => import("./pages/PublicBovine/PublicBovine"));


enum EMarketPlaceRoutes {
	MarketPlace = 'marketPlace',
	ListingDetail = 'listingDetail',
	PublicRanch = 'publicRanch',
	BovineDetail = 'bovineDetail',
}

export const marketPlaceRoutes: IRouteDescriptor = {
	[EMarketPlaceRoutes.MarketPlace]: {
		pattern: ['/public/market-place'],
		url: '/public/market-place',
		component: MarketPlace,
		exact: true,
		provider: MarketPlaceProvider,
	},
	[EMarketPlaceRoutes.ListingDetail]: {
		pattern: ['/public/:ranchId/listings/:listingId'],
		url: '/public/listings',
		component: PublicListing,
		exact: true,
		provider: MarketPlaceProvider,
		urlBuilder: ({ ranchId, listingId }) => `/public/${ranchId}/listings/${listingId}`,
	},
	[EMarketPlaceRoutes.PublicRanch]: {
		pattern: ['/public-ranch/:ranchId'],
		url: '/public-ranch',
		component: PublicRanch,
		exact: true,
		provider: MarketPlaceProvider,
		urlBuilder: ({ranchId}) => `/public-ranch/${ranchId}`,
	},
	[EMarketPlaceRoutes.BovineDetail]: {
		pattern: ['/public/:ranchId/bovines/:bovineId'],
		url: '/public/bovines',
		component: PublicBovine,
		exact: true,
		provider: MarketPlaceProvider,
		urlBuilder: ({ranchId, bovineId}) => `/public/${ranchId}/bovines/${bovineId}`
	}
};
