import { useReducer, FunctionComponent, createContext, Dispatch } from "react";
import { TChildrenProps } from "../../types/common";
import { INIT_RANCH_STATE, ranchContextReducer, ranchStateInit } from "./RanchContext.reducer";
import { IRanchState, TRanchAction } from "./RanchContext.types";


export const RanchStateContext = createContext<IRanchState>(INIT_RANCH_STATE);
export const RanchDispatchContext = createContext<Dispatch<TRanchAction>>(() => null);

export const RanchContextProvider: FunctionComponent<TChildrenProps> = ({
    ...props
}) => {
    const [state, dispatch] = useReducer(
        ranchContextReducer,
        undefined,
        ranchStateInit,
    );
    return (
        <RanchStateContext.Provider value={state}>
            <RanchDispatchContext.Provider value={dispatch}>
                {props.children}
            </RanchDispatchContext.Provider>
        </RanchStateContext.Provider>
    );
};
