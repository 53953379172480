import { makeStyles, Theme } from "@material-ui/core";

export const useFullScreenMediaStyles = makeStyles<Theme>((theme) => ({
    fullScreenHolder: {
        backgroundColor: "black",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        [theme.breakpoints.up("md")]: {
            padding: "2rem",
        },
        zIndex: 8000,
    },
    media: {
        width: "100%", 
        height: "100%", 
        display: "flex", 
        alignItems: "center",
        justifyContent: "center", 
        position: "relative"
    },
    iconHolder: {
        position: "absolute",
        right: "2rem",
        top: "2rem",
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "50%",
        backgroundColor: 'rgba(250,250,250,0.6)',
        transform: 'translate(50%, -50%)',
        cursor: "pointer",
    },
    backIcon: {
        position: "absolute",
        right: "50%",
        top: "50%",
        transform: 'translate(50%, -50%)',
        zIndex: 1,
        fontSize: "2rem",
        color: 'rgba(0, 0, 0, 0.54)',
    },
    prevButton: {
        position: "absolute",
        top: "50%",
        left: "1rem",
        transform: "translate(0,50%)",
    },
    fullScreenButtons: {
        color: theme.palette.common.white,
        cursor: "pointer",
        [`&:hover`]: {
            opacity: "50%",
        },
        
    },
    nextButton: {
        position: "absolute",
        top: "50%",
        right: "1rem",
        transform: "translate(0,50%)",
    }
}))