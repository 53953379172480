import React, { FunctionComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

// Components
import Navbar from './Navbar';
import { TChildrenProps } from '../types/common';
import { FullScreenMedia } from './FullScreenMedia/FullScreenMedia';

export interface LayoutProps {
    children: any;
}

const Layout: FunctionComponent<TChildrenProps> = ({children}) => {
    return (
        <>
            <CssBaseline />
            <Navbar />
            {children}
            <FullScreenMedia />
        </>
    )
};

export default Layout