import { lazy } from "react";
import RedirectRoute from "../common/RedirectRoute/RedirectRoute";
import { RanchContextProvider } from "../ranch/context/RanchContext";
import { IRouteDescriptor } from "../types/common";
const CreateListing = lazy(() => import("./pages/CreateListing/CreateListing"));
const EditListing = lazy(() => import("./pages/EditListing/EditListing"));
const ChooseListing = lazy(() => import("./pages/ChooseListing/ChooseListing"));
const CreateLotListing = lazy(() => import ("./pages/CreateLotListing/CreateLotListing"));

export enum EListingsRoutes {
    EditListing = 'editListing',
    CreateListing = 'createListing',
    AddAnother = 'addAnother',
    CreateLotListing = 'createLotListing',
    ChooseListing = 'chooseListing',
};

export const listingsRoutes: IRouteDescriptor = {
    [EListingsRoutes.CreateListing]: {
        pattern: ['/ranches/listings/create'],
        url: '/ranches/listings/create',
        component: CreateListing,
        exact: true,
        provider: RanchContextProvider,
        protect: true,
    },
    [EListingsRoutes.ChooseListing]: {
        pattern: ['/ranches/listings/choose'],
        url: '/ranches/listings/choose',
        component: ChooseListing,
        exact: true,
        provider: RanchContextProvider,
        protect: true,
    },
    [EListingsRoutes.CreateLotListing]: {
        pattern: ['/ranches/listings/create-lot'],
        url: '/ranches/listings/create-lot',
        component: CreateLotListing,
        exact: true,
        provider: RanchContextProvider,
        protect: true,
    },
    [EListingsRoutes.AddAnother]: {
        pattern: ['/ranches/listings/another'],
        url: '/ranches/listings/another',
        component: RedirectRoute,
        protect: true,
        exact: true,
        redirect: {
            destiny: (params) => {
                if (params && params.get('lot')) {
                    return `/ranches/listings/create-lot`;
                }
                return `/ranches/listings/create`;
            },
        }
    },
    [EListingsRoutes.EditListing]: {
        pattern: ['/ranches/:ranchId/listings/:listingId'],
        url: '/ranches/listings',
        component: EditListing,
        protect: true,
        exact: true,
        urlBuilder: ({ranchId, listingId}) => `/ranches/${ranchId}/listings/${listingId}`,
    },
};
