import { FunctionComponent } from "react";
import { Redirect, RouteComponentProps, useParams } from "react-router";
import { appRoutes } from "../../app/routes";
import { useQueryParams } from "../../hooks/useQueryParams";
import { TRedirectRouteProps } from "./RedirectRoute.types";


const RedirectRoute: FunctionComponent<RouteComponentProps<{[key:string]: any}, {}, any> & TRedirectRouteProps> = ({
    match,
    destiny,
    location,
    ...props
}) => {
    const query = useQueryParams();
    return (
        <Redirect to={{
            pathname: destiny
                ? destiny(query)
                : appRoutes.home.url,
            state: location.state}}
            {...props} />
    );
};

export default RedirectRoute;
