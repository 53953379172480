import React, { FunctionComponent, useCallback } from "react";
import { useFullScreenMediaStyles } from "./FullScreenMedia.styles";
import { TFullScreenMediaProps } from "./FullScreenMedia.types";
import { useAppDispatch, useAppState } from "../../app/context/AppContext.hooks";
import { EAppActionType } from "../../app/context/AppContext.types";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../theme";
import Fab from "../Fab";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IRanchMedia } from "../../ranch/types/ranch";


export const FullScreenMedia: FunctionComponent<TFullScreenMediaProps> = ({
    ...props
}) => {
    const appDispatch = useAppDispatch();
    const appState = useAppState();
    const classes = useFullScreenMediaStyles();
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const handleImageChange = useCallback((next: boolean = false): void => {
        if (appState.fullScreenMedia.mediaSet === undefined) return;
        if (appState.fullScreenMedia.mediaIndex === undefined) return;
        const mediaIndex = appState.fullScreenMedia.mediaIndex;
        const mediaSet = [...appState.fullScreenMedia.mediaSet];
        if (next && mediaIndex >= mediaSet.length - 1) return;
        if (!next && mediaIndex === 0) return;
        let newMedia: IRanchMedia;
        if (next) {
            newMedia = appState.fullScreenMedia.mediaSet[appState.fullScreenMedia.mediaIndex + 1]; 
            appDispatch({
                type: EAppActionType.showFullScreenMedia,
                status: {
                    fullScreenMedia: {
                        show: true,
                        video: (newMedia.url.includes('video') ? newMedia.url : undefined),
                        image: (newMedia.url.includes('video') ? undefined : newMedia.url),
                        mediaIndex: mediaIndex + 1,
                        mediaSet: mediaSet,
                    },
                }
            });
        } else {
            newMedia = appState.fullScreenMedia.mediaSet[appState.fullScreenMedia.mediaIndex - 1]; 
            appDispatch({
                type: EAppActionType.showFullScreenMedia,
                status: {
                    fullScreenMedia: {
                        show: true,
                        video: (newMedia.url.includes('video') ? newMedia.url : undefined),
                        image: (newMedia.url.includes('video') ? undefined : newMedia.url),
                        mediaIndex: mediaIndex - 1,
                        mediaSet: mediaSet,
                    },
                }
            });
        }
    }, [appState, appDispatch]);
    return (
        <div
            className={classes.fullScreenHolder}
            style={{
                display: appState.fullScreenMedia.show
                    ? 'flex'
                    : 'none',
            }}>
            <div className={classes.media}>
                {appState.fullScreenMedia.image
                    ? (
                        <img
                            src={appState.fullScreenMedia.image}
                            style={{
                                width: md ? "auto" : "100%",
                                height: md ? "100%" : "auto",
                            }}
                            alt={appState.fullScreenMedia.image} />
                    ) : (
                        <video
                            autoPlay={false}
                            controls
                            style={{
                                width: md ? "auto" : "100%",
                                height: md ? "100%" : "auto",
                            }}
                            src={appState.fullScreenMedia.video}/>
                    )}
                <Fab 
                    ariaLabel="Go Back" 
                    size="small" 
                    color="default" 
                    clickHandler={e => appDispatch({
                        type: EAppActionType.hideFullScreenMedia,
                        status: {}
                    })} 
                    position="absolute" 
                    coords={{ top: theme.spacing(2), left: theme.spacing(2) }} 
                    disablePortal={true} 
                ><NavigateBeforeIcon /></Fab>
                {appState.fullScreenMedia.mediaIndex !== undefined && 
                    appState.fullScreenMedia.mediaSet !== undefined && (
                        <>
                            {appState.fullScreenMedia.mediaIndex > 0 && (
                                <span className={`${classes.prevButton} ${classes.fullScreenButtons}`}>
                                    <NavigateBeforeIcon 
                                        fontSize="large"
                                        onClick={e => handleImageChange()} />
                                </span>
                            )}
                            {appState.fullScreenMedia.mediaIndex < appState.fullScreenMedia.mediaSet.length - 1 && (
                                <span className={`${classes.nextButton} ${classes.fullScreenButtons}`}>
                                    <NavigateNextIcon 
                                        fontSize="large" 
                                        onClick={e => handleImageChange(true)} />
                                </span>
                            )}
                        </>
                    )}
            </div>
        </div>
    );
};

/* <Fab
    ariaLabel="Previous"
    size="small"
    color="default"
    clickHandler={e => console.log("previous image")}
    position="absolute"
    coords={{left: theme.spacing(2), top: "48vh"}}
    disablePortal={true}> */
