import { createContext, useReducer, Dispatch, useMemo, FunctionComponent } from 'react';
import { TBovineNature } from '../../bovines/types/bovine';
import { TChildrenProps } from '../../types/common';
import { TGermplasmNature } from '../../bovines/types/germplasm';
import { MarketplaceActionTypes, MarketPlaceAction, MarketPlaceState } from './MarketplaceContext.types';
import { BovineBreedValue } from '../../app/types/breed';

export const BREED_LIST_FILTERED_INITIAL_VALUES : BovineBreedValue[] = [];

export const BOVINE_NATURE_LIST_FILTERED_INITIAL_VALUES : TBovineNature[] = [];

export const GERMPLASM_NATURE_LIST_FILTERED_INITIAL_VALUES : TGermplasmNature[] = [];

export const INPUT_SEARCH_BY_NAME_INITIAL_VALUES : string = "";

export const INITIAL_STATE: MarketPlaceState = {
  publicRanch: undefined,
  breedListFiltered: BREED_LIST_FILTERED_INITIAL_VALUES,
  bovineNatureListFiltered: BOVINE_NATURE_LIST_FILTERED_INITIAL_VALUES,
  germplasmNatureListFiltered: GERMPLASM_NATURE_LIST_FILTERED_INITIAL_VALUES,
  inputSearchByName: INPUT_SEARCH_BY_NAME_INITIAL_VALUES,
  nature: []
};

const MarketPlaceContext = createContext<{
  state: MarketPlaceState;
  dispatch: Dispatch<any>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const reducer = (state: MarketPlaceState, action: MarketPlaceAction): MarketPlaceState => {
  switch (action.type) {

    case MarketplaceActionTypes.UPDATE_BREED_LIST_FILTERED:
      state = {
        ...state,
        breedListFiltered: action.result,
      };
      return state;

    case MarketplaceActionTypes.UPDATE_BOVINE_NATURE_LIST_FILTERED:
      state = {
        ...state,
        bovineNatureListFiltered: action.result,
      };
      return state;

    case MarketplaceActionTypes.UPDATE_GERMPLASM_NATURE_LIST_FILTERED:
      state = {
        ...state,
        germplasmNatureListFiltered: action.result,
      };
      return state;

    case MarketplaceActionTypes.UPDATE_INPUT_SEARCH_BY_NAME:
      state = {
        ...state,
        inputSearchByName: action.result,
      };
      return state;

    case MarketplaceActionTypes.SET_PUBLIC_RANCH:
      state = {
        ...state,
        publicRanch: action.result,
      }
      return state;

    case MarketplaceActionTypes.UPDATE_NATURE_LIST:
      return {
        ...state,
        nature: action.result,
      };

    default:
      return state;
  }
};

const MarketPlaceProvider: FunctionComponent<TChildrenProps> = ({
  children
}) => {
  const stateWithCache = {
    ...INITIAL_STATE,
  };

  const [state, dispatch] = useReducer(reducer, stateWithCache);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <MarketPlaceContext.Provider value={{ ...contextValue }}>{children}</MarketPlaceContext.Provider>;
};

export { MarketPlaceProvider, MarketPlaceContext };
