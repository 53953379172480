import { ComponentProps, FunctionComponent, PropsWithChildren } from 'react';
import { useHistory } from 'react-router';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { marketPlaceRoutes } from '../../marketPlace/routes';
import { useFooterStyles } from './Footer.styles';
import { TTranslationNamespace } from '../../i18n.types';
import { Logo } from '../../assets/components/logo';
import { appRoutes } from '../../app/routes';
import { useAppState } from '../../app/context/AppContext.hooks';
import { listingsRoutes } from '../../listings/routes';

const Footer: FunctionComponent<PropsWithChildren<ComponentProps<"div">>> = (props) => {
	const { t } = useTranslation<TTranslationNamespace>("navigation");
  const classes = useFooterStyles();
  const history = useHistory();
  const { me } = useAppState();
	const ranches = me && me.user && me.user.ranches;

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} item>
        <Logo fillColor='#E7EDDD' fillColorText='#E7EDDD'/>
      </Grid>
      <Grid container item alignItems='baseline'>
        <Grid xs={12} item>
          <Button className={classes.buttonLink} onClick={() => history.push(marketPlaceRoutes.marketPlace.url)}>
            {t('searchCattle')}
          </Button>
        </Grid>
        { ranches && (
          <Grid xs={12} item>
            <Button className={classes.buttonLink} onClick={() => history.push(listingsRoutes.chooseListing.url)}>
              {t("addListing")}
            </Button>
          </Grid>
        )}
        <Grid xs={12} item>
          <Button className={classes.buttonLink} onClick={() => history.push(appRoutes.contactUs.url)}>
            {t("contactUsMenu")}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <Button className={`${classes.buttonLink} ${classes.terms}`} onClick={() => history.push(appRoutes.termsAndConditions.url)}>
            {t("terms&Conditions")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
    );
};

export default Footer;
