import React, { Fragment, FunctionComponent, useCallback } from "react";
import { useMemo } from "react";
import { ReactNode } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { useAppState } from "../../app/context/AppContext.hooks";
import { authRoutes } from "../../auth/routes";
import { ranchRoutes } from "../../ranch/routes";
import { TAuthRouteProps } from "./AuthRoute.types";



export const AuthRoute: FunctionComponent<TAuthRouteProps> = ({
    descriptor,
    ...props
}) => {
    const appState = useAppState();
    const render = useCallback((
        Child: FunctionComponent<RouteComponentProps>,
        Provider?: FunctionComponent,
        extraProps?: {
            [key:string]: any
        }
    ): ReactNode => {
        if (Provider) {
            return (
                <Provider>
                    <Child {...props}{...extraProps}/>
                </Provider>
            );
        } else {
            return <Child {...props}{...extraProps}/>;
        }
    }, [props]);
    const extraProps = useMemo(() => {
        let extra: {
            [key:string]: any
        } = {};
        if (descriptor.route.redirect !== undefined) {
            extra = {
                ...extra,
                ...descriptor.route.redirect,
            };
        }
        return extra;
    }, [descriptor]);
    return (
        <Fragment>
            {descriptor.route.protect 
                ? appState.me 
                    ? (
                        <Fragment>
                            {descriptor.route.provider 
                            ? render(
                                descriptor.route.component,
                                descriptor.route.provider,
                                extraProps,
                            ) : render(
                                descriptor.route.component,
                                undefined, 
                                extraProps
                            )}
                        </Fragment>
                    ) : <Redirect to={authRoutes.logIn.url}/>
                : descriptor.route.discard && appState.me
                    ? <Redirect to={ranchRoutes.ranchProfile.url}/>
                    : (
                        <Fragment>
                            {descriptor.route.provider 
                            ? render(
                                descriptor.route.component,
                                descriptor.route.provider,
                            ) : render(
                                descriptor.route.component,
                            )}
                        </Fragment>
                    )}
        </Fragment>
    );
};
