import { Dispatch, useContext } from "react";
import { AppDispatchContext, AppStateContext } from "./AppContext";
import { IAppState, TAppAction } from "./AppContext.types";


export const useAppState = (): IAppState => {
    const context = useContext(AppStateContext);
    if (context === undefined) {
        throw new Error(
            'useAppState out ot bounds.'
        );
    }
    return context;
};

export const useAppDispatch = (): Dispatch<TAppAction> => {
    const context = useContext(AppDispatchContext);
    if (context === undefined) {
        throw new Error(
            'useAppDispatch out ot bounds.'
        );
    }
    return context;
};
