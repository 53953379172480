import { IGTMElements } from "./GoogleTagManager.types";
import { Snippets } from "./Snippets";
import { TDataLayerArgs, TSnippetsArgs } from "./Snippets.types";

export class GoogleTagManager {
    noScript: HTMLElement;
    script: HTMLScriptElement;
    dataScript: HTMLScriptElement;
    constructor(args: TSnippetsArgs) {
        const elements = this.buildGTMElements(args);
        this.noScript = elements.noScript;
        this.script = elements.script;
        this.dataScript = elements.dataScript;
    }
    buildDataScript(dataLayer: string): HTMLScriptElement {
        const scriptTag = document.createElement("script");
        scriptTag.innerHTML = dataLayer;
        return scriptTag;
    }
    buildGTMElements(args: TSnippetsArgs): IGTMElements {
        const snippets = new Snippets(args);
        const noScript = document.createElement("noscript");
        noScript.innerHTML = snippets.iframe;
        const script = document.createElement("script");
        script.innerHTML = snippets.script;
        const dataScript = this.buildDataScript(snippets.dataLayerVar);
        return {
            noScript,
            script,
            dataScript
        };
    }
    static initialize({
        id,
        events = {},
        dataLayer = undefined,
        dataLayerName = "dataLayer",
        auth = "",
        preview = ""
    }: Partial<Omit<TSnippetsArgs, "id">> & Pick<TSnippetsArgs, "id">
    ): GoogleTagManager {
        const gtm = new GoogleTagManager({
            id,
            events,
            dataLayer,
            dataLayerName,
            auth,
            preview
        });
        if (dataLayer) document.head.appendChild(gtm.dataScript);
        document.head.insertBefore(gtm.script, document.head.childNodes[0]);
        document.head.insertBefore(gtm.noScript, document.head.childNodes[0]);
        return gtm;
    }
    addDataLayer(args: TDataLayerArgs): void {
        // @ts-expect-error: cleaner than messing with globalThis window.
        if (window[args.dataLayerName]) return window[args.dataLayerName].push(args.dataLayer);
        const dataLayerVar = Snippets.buildDataLayer(args);
        const dataScript = this.buildDataScript(dataLayerVar);
        this.dataScript = dataScript;
        document.head.insertBefore(this.dataScript, document.head.childNodes[0]);
    }
}
