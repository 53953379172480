import { lazy } from "react";
import { RanchContextProvider } from "../ranch/context/RanchContext";
import { IRouteDescriptor } from "../types/common"
const BovineDetail = lazy(() => import("./components/BovineDetail/BovineDetail"));
const CreateBovine = lazy(() => import("./pages/CreateBovine/CreateBovine"));
const EditBovine = lazy(() => import("./pages/EditBovine/EditBovine"));

export enum EBovinesRoutes {
    CreateBovine = 'createBovine',
    BovineDetail = 'bovineDetail',
    EditBovine = 'editBovine',
};

export const bovinesRoutes: IRouteDescriptor = {
    [EBovinesRoutes.CreateBovine]: {
        pattern: ['/ranch/bovines/create'],
        url: '/ranch/bovines/create',
        component: CreateBovine,
        exact: true,
        provider: RanchContextProvider,
        protect: true,
    },
    [EBovinesRoutes.BovineDetail]: {
        pattern: ['/ranch/bovines/:bovineId'],
        url: '/ranch/bovines/:bovineId',
        component: BovineDetail,
        exact: true,
        provider: RanchContextProvider,
        protect: true,
        urlBuilder: ({bovineId}) => `/ranch/bovines/${bovineId}`,
    },
    [EBovinesRoutes.EditBovine]: {
        pattern: ['/ranches/:ranchId/bovines/:bovineId'],
        url: '/ranches/bovines',
        component: EditBovine,
        protect: true,
        exact: true,
        urlBuilder: ({ranchId, bovineId}) => `/ranches/${ranchId}/bovines/${bovineId}`,
    }
};
