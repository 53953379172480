import { BovineBreedValue } from '../../app/types/breed';
import { TBovineNature } from '../../bovines/types/bovine';
import { TGermplasmNature } from '../../bovines/types/germplasm';
import { TListingNature } from '../../listings/types/listing';
import { IPublicRanchResponse } from '../types/marketPlace';

export type MarketPlaceState = {
  breedListFiltered: BovineBreedValue[];
  bovineNatureListFiltered: TBovineNature[];
  germplasmNatureListFiltered: TGermplasmNature[];
  inputSearchByName: string;
  publicRanch?: IPublicRanchResponse;
  nature?: TListingNature[];
};

export enum MarketplaceActionTypes {
  UPDATE_NATURE_LIST = 'UPDATE_NATURE_LIST',
  SET_PUBLIC_RANCH = 'SET_PUBLIC_RANCH',
  UPDATE_BREED_LIST_FILTERED = 'UPDATE_BREED_LIST_FILTERED',
  UPDATE_BOVINE_NATURE_LIST_FILTERED = 'UPDATE_BOVINE_NATURE_LIST_FILTERED',
  UPDATE_GERMPLASM_NATURE_LIST_FILTERED = 'UPDATE_GERMPLASM_NATURE_LIST_FILTERED',
  UPDATE_INPUT_SEARCH_BY_NAME = 'UPDATE_INPUT_SEARCH_BY_NAME'
}

export type MarketPlaceAction = ReturnType<
  typeof updateBreedListFiltered | typeof updateBovineNatureListFiltered |
  typeof updateGermplasmNatureListFiltered | typeof updateInputSearchByName |
  typeof setPublicRanch | typeof updateNatureList
>;

function updateNatureList(natureList: TListingNature[]) {
  return <const>{
    type: MarketplaceActionTypes.UPDATE_NATURE_LIST,
    result: natureList
  };
}

function updateBreedListFiltered(breedListFiltered: BovineBreedValue[]) {
  return <const>{
    type: MarketplaceActionTypes.UPDATE_BREED_LIST_FILTERED,
    result: breedListFiltered
  };
}

function updateBovineNatureListFiltered(bovineNatureListFiltered: TBovineNature[]) {
  return <const>{
    type: MarketplaceActionTypes.UPDATE_BOVINE_NATURE_LIST_FILTERED,
    result: bovineNatureListFiltered
  };
}

function updateGermplasmNatureListFiltered(germplasmNatureListFiltered: TGermplasmNature[]) {
  return <const>{
    type: MarketplaceActionTypes.UPDATE_GERMPLASM_NATURE_LIST_FILTERED,
    result: germplasmNatureListFiltered
  };
}

function updateInputSearchByName(inputSearchByName: string) {
  return <const>{
    type: MarketplaceActionTypes.UPDATE_INPUT_SEARCH_BY_NAME,
    result: inputSearchByName
  };
}

function setPublicRanch(publicRanch: IPublicRanchResponse) {
  return <const>{
    type: MarketplaceActionTypes.SET_PUBLIC_RANCH,
    result: publicRanch
  };
};
