import { IMonthlyUsersKpiReport, IWeeklyUsersKpiReport } from '../types/KPIs';

export type KpiState = {
  monthlyUsersKpiReport : IMonthlyUsersKpiReport
  weeklyUsersKpiReport : IWeeklyUsersKpiReport,
};

export enum KpiActionTypes {
  SET_MONTHLY_KPI = 'SET_MONTHLY_KPI',
  SET_WEEKLY_KPI = 'SET_WEEKLY_KPI',
}

export type KpiAction = ReturnType<
  typeof setMonthlyKpi | typeof setWeeklyKpi
>;

function setMonthlyKpi(monthlyUsersKpiReport : IMonthlyUsersKpiReport) {
  return <const>{
    type: KpiActionTypes.SET_MONTHLY_KPI,
    result: monthlyUsersKpiReport
  };
}

function setWeeklyKpi(weeklyUsersKpiReport: IWeeklyUsersKpiReport) {
  return <const>{
    type: KpiActionTypes.SET_WEEKLY_KPI,
    result: weeklyUsersKpiReport
  };
}

