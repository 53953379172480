import { lazy } from "react";
import { IRouteDescriptor } from "../types/common";
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const EditPassword = lazy(() => import("./pages/EditPassword"));

export enum AuthRoutes {
    LogIn = 'logIn',
    SignUp = 'signUp',
    ResetPassword = 'resetPassword',
    EditPassword = 'editPassword',
};

export const authRoutes: IRouteDescriptor = {
    [AuthRoutes.LogIn]: {
        pattern: ['/auth/login'],
        url: '/auth/login',
        component: Login,
        discard: true,
    },
    [AuthRoutes.SignUp]: {
        exact: true,
        pattern: ['/auth/signup', '/auth/signup/:stepId'],
        url: '/auth/signup',
        component: SignUp,
        discard: true,
    },
    [AuthRoutes.ResetPassword]: {
        pattern: ['/auth/reset-password'],
        url: '/auth/reset-password',
        component: ResetPassword,
        discard: true,
    },
    [AuthRoutes.EditPassword]: {
        pattern: ['/auth/password/edit'],
        url: '/auth/password/edit',
        component: EditPassword,
    },
};
