import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, Button, Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { appRoutes } from '../app/routes';
import { useAppDispatch, useAppState } from '../app/context/AppContext.hooks';
import { EAppActionType } from '../app/context/AppContext.types';
import { ranchRoutes } from '../ranch/routes';
import { authRoutes } from '../auth/routes';
import { marketPlaceRoutes } from '../marketPlace/routes';
import { kpiRoutes } from '../kpi/routes';
import { listingsRoutes } from '../listings/routes';
import { Logo } from '../assets/components/logo';
import Switcher from './Switcher/Switcher'
import { useTranslation } from 'react-i18next';
import { TTranslationNamespace } from '../i18n.types';

interface MenuProps {
	isMobile?: boolean;
	closeDrawer?: () => void;
}

const useStyles = makeStyles<Theme, MenuProps>((theme) => ({
	root: {
		display: ({ isMobile }) => (!isMobile ? 'flex' : 'block'),
		[theme.breakpoints.up('md')]: {
      height: "65px"
    }
	},
	item: {
		width: ({ isMobile }) => (!isMobile ? 'auto' : '200px'),
	},
	link: {
		color: theme.palette.primary.light,
		textDecoration: 'none',
		fontFamily: 'GillSans',
		fontSize: '19px',
		'&.active': { color: theme.palette.primary.main },
	},
	logo: {
		'&:hover': {
			cursor: 'pointer',
		}
	},
	logoLink: {
		textDecoration: 'none',
	}
}));

const Menu: React.FC<MenuProps> = ({ isMobile, closeDrawer, ...props }) => {
	const { t } = useTranslation<TTranslationNamespace>("navigation");
	const classes = useStyles({ isMobile: isMobile });
	const { push } = useHistory();
	const dispatch = useAppDispatch();
	const { me } = useAppState();
	const ranches = me && me.user && me.user.ranches;
	const handleLogout = () => {
		dispatch({
			type: EAppActionType.updateMe,
			status: {
				me: undefined
			}
		});
		localStorage.removeItem('credentials');
		closeDrawer && closeDrawer();
	};
	return (
		<>
			<Box justifyContent={'space-between'} flexDirection={!isMobile ? 'row' : 'column'} display="flex" flexGrow={1}>
				<List className={classes.root} {...props}>
					{isMobile && (
						<ListItem className={classes.item}>
							<NavLink className={classes.logoLink} to={appRoutes.home.url} onClick={() => closeDrawer && closeDrawer()}>
								<div className={classes.logo}>
									<Logo alt="logo"/>
								</div>
							</NavLink>
						</ListItem>
					)}
					<ListItem className={classes.item}>
						<ListItemText>
							<NavLink to={marketPlaceRoutes.marketPlace.url} className={classes.link} onClick={() => closeDrawer && closeDrawer()}>
								{t("searchCattle")}
							</NavLink>
						</ListItemText>
					</ListItem>
					{me && (
						<>
							{ranches && ranches?.length > 0
								? (
									<>
										<ListItem className={classes.item}>
											<ListItemText>
												<NavLink to={ranchRoutes.ranchProfile.url} className={classes.link} onClick={() => closeDrawer && closeDrawer()}>
													{ranches[0].name}
												</NavLink>
											</ListItemText>
										</ListItem>
										<ListItem className={classes.item}>
											<ListItemText>
												<NavLink 
													// to={listingsRoutes.createListing.url}
													to={listingsRoutes.chooseListing.url}
													onClick={() => closeDrawer && closeDrawer()} 
													className={classes.link}>
														{t("addListing")}
												</NavLink>
											</ListItemText>
										</ListItem>
									</>
								) : (
									<ListItem className={classes.item}>
										<ListItemText>
											<NavLink to={ranchRoutes.createRanch.url} className={classes.link} onClick={() => closeDrawer && closeDrawer()}>
												{t("forBreeders")}
											</NavLink>
										</ListItemText>
									</ListItem>
								)
							}
						</>
					)}
					
					{me && (
						<ListItem className={classes.item}>
							<ListItemText>
								<NavLink to={appRoutes.help.url} className={classes.link} onClick={() => closeDrawer && closeDrawer()}>
									{t("help")}
								</NavLink>
							</ListItemText>
						</ListItem>
					)}

					{me?.user.is_staff && (
						<ListItem className={classes.item}>
							<ListItemText>
								<NavLink to={kpiRoutes.kpi.url} className={classes.link} onClick={() => closeDrawer && closeDrawer()}>
									KPIs
								</NavLink>
							</ListItemText>
						</ListItem>
					)}
					<ListItem className={classes.item}>
						<ListItemText>
							<NavLink to={appRoutes.contactUs.url} className={classes.link} onClick={() => closeDrawer && closeDrawer()}>
								{t("contactUsMenu")}
							</NavLink>
						</ListItemText>
					</ListItem>
				</List>
				<List className={classes.root} {...props}>
					{/* <ListItem className={classes.item}>
						<Switcher /> Switch for change the language
					</ListItem> */}
					{!me ? (
						<>
							<ListItem className={classes.item}>
								<Button color="inherit" fullWidth={true} onClick={() => {
									push(authRoutes.signUp.url);
									closeDrawer && closeDrawer();
								}}>
									{t("signUp")}
								</Button>
							</ListItem>

							<ListItem className={classes.item}>
								<Button color="primary" variant="contained" fullWidth={true} onClick={() => {
									push(authRoutes.logIn.url);
									closeDrawer && closeDrawer();
								}}>
									{t("signIn")}
								</Button>
							</ListItem>
						</>
					) : (
						<ListItem>
							<Button color="primary" fullWidth={true} variant="contained" onClick={handleLogout}>
								{t("logOut")}
							</Button>
						</ListItem>
					)}
				</List>
			</Box>
		</>
	);
};

export default Menu;
