import useAxios from "axios-hooks";
import { useCallback, useEffect, useState } from "react";
import { IMe, IUserData } from "../../../auth/types/user";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useAppDispatch, useAppState } from "../../context/AppContext.hooks";
import { EAppActionType } from "../../context/AppContext.types";
import { IGetMeHookData, TUseGetMe } from "./useGetMe.types";


function useGetMe({
    trigger,
}: IGetMeHookData): TUseGetMe {
    const [mount, setMount] = useState<boolean>(false);
    const [result, setResult] = useState<boolean | undefined>(undefined);
    const [{data, response, error, loading}, execute] = useAxios<IUserData>({ 
        method: "get" 
    }, { 
        manual: true, 
    });
    const [storedCredentials, setStoredCredentials] = useLocalStorage<IMe | undefined>(
        'credentials',
        undefined,
    );
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const fetchMe = useCallback(
        async() => {
            await execute({
                url: `/users/${storedCredentials?.user.id}`,
                headers: {
                    'Authorization': `Bearer ${storedCredentials?.jwt}`,
                    'Content-Type': 'application/json'
                },
            });
        },
    [storedCredentials, execute]);

    useEffect(() => {
        if (!storedCredentials) {
            setResult(false);
            return;
        }
        if (!appState.me && mount && result === undefined) {
            fetchMe();
        }
        if (!mount) {
            setMount(true);
        }
    }, [appState.me, fetchMe, mount, storedCredentials, result]);

    useEffect(() => {
        if (data && storedCredentials && !result) {
            const me: IMe = {
                jwt: storedCredentials.jwt,
                user: data,
            }
            appDispatch({
                type: EAppActionType.updateMe,
                status:  {
                    me: me,
                },
            });
            setStoredCredentials(me);
            setResult(true);
        }
    }, [data, appDispatch, storedCredentials, result, setStoredCredentials]);
    useEffect(() => {
        if (error && !result) {
            appDispatch({
                type: EAppActionType.updateMe,
                status:  {
                    me: undefined,
                },
            });
            setStoredCredentials(undefined);
            setResult(false);
        }
    }, [error, appDispatch, setStoredCredentials, result]);

    return { 
        data, 
        loading, 
        error, 
        response, 
        execute,
        result,
    };
};

export default useGetMe;
