import { lazy } from "react";
import { MarketPlaceProvider } from "../marketPlace/context/MarketplaceContext";
import { IRouteDescriptor } from "../types/common";
const Home = lazy(() => import("./pages/Home/Home"));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'))
const Help = lazy(() => import('./pages/Help/Help'))
const TermsAndConditions = lazy(()=> import('./pages/TermsAndConditions/TermsAndConditions'))

enum EAppRoutes {
    ContactUs = 'contactUs',
    Home = 'home',
    Help = 'help',
    TermsAndConditions = 'termsAndConditions'
}

export const appRoutes: IRouteDescriptor = {
    [EAppRoutes.Home]: {
        pattern: ['/', '/home'],
        url: '/',
        component: Home,
        exact: true,
        provider: MarketPlaceProvider,
    },
    [EAppRoutes.ContactUs]: {
        pattern: ['/contact-us'],
        url: '/contact-us',
        exact: true,
        component: ContactUs,
    },
    [EAppRoutes.Help]: {
        pattern: ['/help'],
        url: '/help',
        exact: true,
        component: Help,
        protect: true
    },
    [EAppRoutes.TermsAndConditions]: {
        pattern: ['/terms-and-conditions'],
        url:'/terms-and-conditions',
        exact: true,
        component: TermsAndConditions,
    },
};
